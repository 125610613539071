import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/common/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";
import config from "./constants/config";
import { ThemePage } from "./pages/ThemePage";
import { BrandingPage } from "./pages/BrandingPage";
import { LanguagePage } from "./pages/LanguagePage";
import { NavigationPage } from "./pages/NavigationPage";
import { CategoryTypePage } from "./pages/CategoryTypePage";
import { BlogPage } from "./pages/Blog";
import { useModulePrivilage } from "./store/modulePrivilageContext";
import { ModuleNames } from "./utils/enums";

const Login = React.lazy(() => import("./pages/common/Login"));
const Dashboard = React.lazy(() => import("./pages/common/Dashboard"));
const NotFound = React.lazy(() => import("./pages/common/NotFound"));
const ConfigPage = React.lazy(() => import("./pages/Config"));
const GroupPage = React.lazy(() => import("./pages/Group"));
const CategoryPage = React.lazy(() => import("./pages/CategoryPage"));
const CategoryDetailsPage = React.lazy(() => import("./pages/CategoryDetailsPage"));
const TopicPage = React.lazy(() => import("./pages/TopicPage"));
const TopicField = React.lazy(() => import("./pages/TopicField"));
const MetaTags = React.lazy(() => import("./pages/MetaTagsPage"));

function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  const routesConstatnts = config.cmsConfig.routesConstatnts;
  const { moduleList } = useModulePrivilage();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppReady(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [moduleList]);

  // Function to render the route based on module name received from server
  const renderRoute = (moduleName: string) => {
    switch (moduleName) {
      case ModuleNames.THEME:
        return <Route path={routesConstatnts.routeTheme} element={<ThemePage />} />;
      case ModuleNames.BRANDING:
        return <Route path={routesConstatnts.routeBranding} element={<BrandingPage />} />;
      case ModuleNames.CONFIG:
        return <Route path={routesConstatnts.routeConfig} element={<ConfigPage />} />;
      case ModuleNames.LANGUAGE:
        return <Route path={routesConstatnts.routeLanguage} element={<LanguagePage />} />;
      case ModuleNames.GROUP:
        return <Route path={routesConstatnts.routeGroup} element={<GroupPage />} />;
      case ModuleNames.NAVIGATION:
        return <Route path={routesConstatnts.routeNavigation} element={<NavigationPage />} />;
      case ModuleNames.CATEGORY:
        return <Route path={routesConstatnts.routeCategory} element={<CategoryPage />} />;
      case ModuleNames.CATEGORY_TYPE:
        return <Route path={routesConstatnts.routeCategoryType} element={<CategoryTypePage />} />;
      case ModuleNames.CATEGORY_DETAILS:
        return <Route path={routesConstatnts.routeCategoryDetails} element={<CategoryDetailsPage />} />;
      case ModuleNames.BLOG:
        return <Route path={routesConstatnts.routeBlog} element={<BlogPage />} />;
      case ModuleNames.TOPIC:
        return <Route path={routesConstatnts.routeTopic} element={<TopicPage />} />;
      case ModuleNames.TOPIC_FIELDS:
        return <Route path={routesConstatnts.routeTopicFields} element={<TopicField />} />;
      case ModuleNames.META_TAGS:
        return <Route path={routesConstatnts.routeMetaTags} element={<MetaTags />} />;
      default:
        return null;
    }
  };


  const renderBasedOnCondition = (): JSX.Element => {
    return (
      <Route element={<AuthLayout />}>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          {/* Dynamically render routes based on moduleList */}
          {moduleList.map((module) => (
            <React.Fragment key={module.id}>
              {renderRoute(module.name)}
            </React.Fragment>
          ))}
        </Route>
      </Route>
    );
  }

  if (!isAppReady) {
    return <LoadingSpinner />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {renderBasedOnCondition()}
          <Route path="/healthcheck" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;