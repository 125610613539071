import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { IDataTableProps } from "../../../../interfaces/interfaceCommon";
import { useState } from "react";
import { Button, FormControl, InputAdornment, MenuItem, Select, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { InputBox } from "../inputBox/inputBox";
import classes from "./dataTable.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import { NoRecords } from "./noRecords";
import { isNonEmptyArr } from "../../../../utils/utils";
// import { images } from "../../../../constants";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

export const DataTable: React.FunctionComponent<IDataTableProps> = ({ rowData, columns, rowsPerPageOptions, shouldShowDatePicker, shouldShowSearch, onPageChange, shouldShowActionButton, onClickActionButton, filterKey, filterOptions = [] }) => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string | ''>('');

  const handleFromDateChange = (date: Dayjs | null) => {
    setFromDate(date);
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCategory(event.target.value as string);
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setToDate(date);
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setSearch(value);
  }

  const filteredRows = isNonEmptyArr(rowData) ? rowData.filter((row) => {
    const createdAtDate = dayjs(row.createdAt);

    // Filter by date range
    const isAfterFromDate = !fromDate || createdAtDate.isAfter(fromDate, 'day') || createdAtDate.isSame(fromDate, 'day');
    const isBeforeToDate = !toDate || createdAtDate.isBefore(toDate, 'day') || createdAtDate.isSame(toDate, 'day');

    // Filter by search query
    const lowerCaseSearchQuery = search.toLowerCase();
    const isNumericSearch = !isNaN(lowerCaseSearchQuery as any) && lowerCaseSearchQuery !== '';
    let matchesSearch = false;

    // Iterate through all properties of the row object and check if any value matches the search query
    Object.values(row).forEach((value) => {
      const stringValue = String(value).toLowerCase();
      if (typeof value === 'string' && stringValue.includes(lowerCaseSearchQuery)) {
        matchesSearch = true;
      } else if (isNumericSearch) {
        // Check if the value is numeric and matches the search query
        const numericValue = parseFloat(lowerCaseSearchQuery);
        if (!isNaN(numericValue) && numericValue === value) {
          matchesSearch = true;
        }
      }
    });


    // Filter by category
    const matchesCategory = selectedCategory == "" || (filterKey === "Category"
      ? row[filterKey]?.id === selectedCategory
      : row[filterKey as string] === selectedCategory);


    if (shouldShowSearch && shouldShowDatePicker) {
      return isAfterFromDate && isBeforeToDate && matchesSearch && matchesCategory;
    } else if (shouldShowSearch) {
      return matchesSearch && matchesCategory;
    } else if (shouldShowDatePicker) {
      return isAfterFromDate && isBeforeToDate && matchesCategory;
    } else {
      return matchesCategory;
    }
  }) : [];

  const CustomNoRowsOverlay = () => {
    return <NoRecords />
  };

  const showActionButton = () => {
    return shouldShowActionButton
  }

  const calculateMaxWidth = () => {
    if (shouldShowDatePicker && shouldShowActionButton) {
      return "40%";
    } else if (shouldShowDatePicker || shouldShowActionButton) {
      return "60%";
    }
    return "100%";
  }


  return (
    <div style={{ width: '100%', minHeight: `${rowData?.length === 0 ? "auto" : "400px"}`, height: `${rowData?.length === 0 ? "400px" : "auto"}` }}>
      <div className={classes.topFilterWrapper}>
        {shouldShowDatePicker && <Stack display={"flex"} flexDirection={"row"} gap={2} maxWidth={"40%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select from date"
              value={fromDate}
              onChange={handleFromDateChange}
              disabled={!rowData.length}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select to date"
              value={toDate}
              onChange={handleToDateChange}
              disabled={!rowData.length}
            />
          </LocalizationProvider>
        </Stack>}
        {shouldShowSearch && <Stack sx={{ display: "flex", gap: "8px", flexDirection: "row", flex: 1 }}>
          <Stack flex={2}>
            <InputBox
              value={search}
              onChangeHandler={handleSearch}
              label="search records"
              variant="outlined"
              name="search"
              id="search"
              startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
              disabled={!rowData?.length}
            />
          </Stack>
          {filterKey && isNonEmptyArr(filterOptions) && <Stack sx={{ flex: 1, maxWidth: "200px" }}>
            <FormControl>
              <Select
                labelId="category-select-label"
                value={selectedCategory}
                onChange={(e) => handleCategoryChange(e as any)}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>
                {filterOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>}
        </Stack>}

        {showActionButton() && <Stack className={classes.actionButton}>
          <Button size="large" disableRipple variant="text" onClick={onClickActionButton} style={{ background: "transparent" }} startIcon={<AddCircleRoundedIcon />}>{
            // <img src={images.plusBtn} alt="Icon" style={{ width: "2rem", height: "2rem" }} />
          }</Button>
        </Stack>}
      </div>

      <DataGrid
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '0.630rem',
            padding: '0 5px'
          },
          [`& .MuiDataGrid-columnHeaders`]: {
            fontSize: '0.700rem',
          },
        }}
        rows={filteredRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        disableAutosize
        pageSizeOptions={rowsPerPageOptions}
        checkboxSelection={false}
        getRowId={(rowData) => rowData.id}
        rowHeight={36}
        columnHeaderHeight={45}
        paginationMode="client"
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
        onPaginationModelChange={onPageChange}
        className="dataTableMain"
      />
    </div>
  );
}
