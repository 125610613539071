import { useEffect, useState } from "react"
import { ICommonReturnType, ILanguageListProps } from "../../interfaces/interfaceCommon";
import config from "../../constants/config";
import getService from "../../services/getService";
import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../common/UI/table/dataTable";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { useModal } from "../../store/modalContext";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { DeleteModal } from "../common/actionButtons/DeleteModal";
import { AddLanguage } from "./AddLanguage";
import { useModulePrivilage } from "../../store/modulePrivilageContext";

export const Language = () => {
    const [languageData, setLanguageData] = useState<ILanguageListProps[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { openModal, closeModal, modalId } = useModal();
    const { canAdd, canEdit, canDelete } = useModulePrivilage();
    useEffect(() => {
        fetchLanguageList();
    }, []);

    const fetchLanguageList = async () => {
        setIsDataLoading(true);
        try {
            const url = config.cmsConfig.apiEndpoint.languageList;
            const result: ICommonReturnType = await getService(url);
            setLanguageData(result.data);
        } catch (error: any) {
            console.log(error.message);
        } finally {
            setIsDataLoading(false);
        }
    };

    const createColumns = () => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center",
                align: "center"
            },
            {
                field: 'name', headerName: 'Name', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'displayFor', headerName: 'Display for', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'isMobile', headerName: 'Mobile', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'isActive', headerName: 'Active', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            ...((canEdit || canDelete) ? [
                {
                    field: 'Actions', headerName: 'Actions', sortable: false, resizable: false, flex: 1, headerAlign: "center" as GridAlignment,
                    align: "center" as GridAlignment, renderCell: (params:any) => {
                        return (
                            <ActionButtons name={params.row.name}
                                deleteModal={<DeleteModal closeModal={closeModal} modalId={modalId} name={params.row.name} refetch={fetchLanguageList} deleteUrl={`${config.cmsConfig.apiEndpoint.deleteLanguage}/${params.row.id}`} />}
                                editModal={<AddLanguage closeModal={closeModal} modalId={modalId} refetch={fetchLanguageList} id={params.row.id} />}
                            />
                        )
                    },
                },
            ] : []),
        ];
        return columns;
    };

    const handleActionButtonClick = () => {
        const modalContent = <AddLanguage closeModal={closeModal} modalId={modalId} refetch={fetchLanguageList} />;
        openModal(modalContent, "Add Language")
    }

    return (
        <div>
            {isDataLoading ? <LoadingSpinner /> :
                <DataTable rowData={languageData}
                    columns={createColumns()}
                    rowsPerPageOptions={[10, 25]}
                    shouldShowDatePicker={false}
                    shouldShowSearch={true}
                    shouldShowActionButton={canAdd ? true : false}
                    actioButtonText="Add Language"
                    onClickActionButton={handleActionButtonClick}
                />
            }
        </div>
    )
}