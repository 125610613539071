import { ICommonStringOptions } from "../interfaces/interfaceCommon";

export const Payment = {
    RETURNID: "returnId"
};

/** These names needs to be same as registered modules names in DB */
export const ModuleNames = {
    DASHBOARD: "Dashboard",
    THEME: "Theme",
    BRANDING: "Branding",
    CONFIG: "Config",
    LANGUAGE: "Language",
    GROUP: "Group",
    NAVIGATION: "Navigation",
    CATEGORY: "Category",
    CATEGORY_TYPE: "Category Type",
    CATEGORY_DETAILS: "Category Details",
    BLOG: "Blog",
    TOPIC: "Topic",
    TOPIC_FIELDS: "Topic Fields",
    META_TAGS: "Meta Tags",
};

export const RoutesConstatnts = {
    routeDashboard: "/",
    routeTheme: "/theme",
    routeBranding: "/branding",
    routeConfig: "/config",
    routeLanguage: "/language",
    routeGroup: "/group",
    routeNavigation: "/navigation",
    routeCategory: "/category",
    routeCategoryType: "/categorytype",
    routeBlog: "/blog",
    routeCategoryDetails:"/categoryDetails",
    routeTopic:"/topic",
    routeTopicFields:"/topicfields",
    routeMetaTags:"/metatags"
};

export const priviligeOptions: ICommonStringOptions[] = [
    { id: 'canView', name: 'View', },
    { id: 'canEdit', name: 'Edit' },
    { id: 'canDelete', name: 'Delete' },
    { id: 'canAdd', name: 'Add' }
];

export const WhitelabelType = {
    B2B: "B2B",
    B2C: "B2C"
};

export const ColorPallateOptions = {
    COLOR:"Color",
    TEXT: "Text"
};